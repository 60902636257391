<!--<template>-->

<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  name: "Main"-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->

<!--</style>-->


<template>
  <el-main class="index-container">
    <el-row style="width:1200px;">
<!--      <el-col class="side_menu" style="float:left;width:240px;">-->
<!--        <Side/>-->
<!--      </el-col>-->
      <el-col  style="margin-left:20px; float:left; width: 940px;">
        <div style="height:320px">
          <el-carousel :interval="5000" arrow="always">
            <el-carousel-item v-for="(item,index) in bannerArr" :key="index">
              <router-link style="width:100%;height:100%;display:inline-block;" :to="item.mbanUrl">
                <img :src="require(item.mbanPhoto)" >
              </router-link>

            </el-carousel-item>
          </el-carousel>
        </div>
<!--        <div style="height:320px" v-for="(item,index) in bannerArr" :key="index">-->
<!--        <img src="@/pages/images/banner1.jpg" class="car_img">-->
<!--        </div>-->
        <div class="index-message">
          <div class="index-message__list">
            <router-link to="/details" >
              <h5>等级保护</h5>
              <p>等级保护是国家基本网络安全制度、基本国策,欢迎咨询！</p>
              <img src="@/assets/images/a02.png">
            </router-link>
            <router-link to="/yitihua" >
              <h5>等保整改</h5>
              <p>云上业务系统<br/>等保一体化解决方案</p>
              <img src="@/assets/images/a10.png">
            </router-link>
            <router-link to="/dengbaohegui" >
              <h5>合规套餐</h5>
              <p>云上业务系统 <br/> 等保合规套餐</p>
              <img src="@/assets/images/a09.png">
            </router-link>
          </div>
          <div class="index-message__login">
            <div class="block">
              <el-avatar :size="50" :src="circleUrl"></el-avatar>
            </div>
            <div>
              <p class="ints">Hi,欢迎来到万方平台</p>
            </div>
            <div class="lo_box">
              <router-link to="/">登录</router-link>
              <router-link to="/">注册</router-link>
            </div>

            <div class="half_link">
              <router-link target="_blank" to="/dengbaohegui">等保合规</router-link>
              <el-divider direction="vertical"></el-divider>
              <a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">免费咨询</a>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>


  </el-main>
</template>
<script type="text/javascript">
// import Side from "./Side"
export default{
  name: "Main",
  components:{
    // Side
  },
  data(){
    return{
      circleUrl:
          "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      // bannerArr:[
      //       {img:require('@/assets/images/banner01.jpg'),url:"/details"},
      //       {img:require('@/assets/images/banner02.jpg'),url:"/dengbaohegui"},
      //       {img:require('@/assets/images/banner03.jpg'),url:"/yitihua"},
      //       {img:require('@/assets/images/banner04.jpg'),url:"/dengbaomeal"}
      //     ]
      // bannerArr:[
      //       {mbanPhoto:'/pages/images/banner1.jpg',mbanUrl:"/details"},
      //       {mbanPhoto:require('@/pages/images/banner1.jpg'),mbanUrl:"/dengbaohegui"},
      //       {mbanPhoto:require('@/pages/images/banner1.jpg'),mbanUrl:"/yitihua"},
      //       {mbanPhoto:require('@/pages/images/banner1.jpg'),mbanUrl:"/dengbaomeal"}
      //     ]



      bannerArr:[]

    }
  },
  methods: {
    page() {
      const _this = this
      _this.$axios.get("/MainBannersAll").then(res => {
        var data = res.data.data
        _this.bannerArr = data
        this.$message({
          message: _this.bannerArr,
          type: 'success',
        });


      })
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  },
  created() {
    this.page();
    console.log(123);
  }


};
</script>


<style type="text/css" scoped>
.index-container {
  width:1200px;
  margin:0 auto;
  overflow:hidden;
  padding-left:0;
  padding-right:0;
}
.el-divider {
  background-color: #999999;
}
.side_menu{
  background: #fff;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.el-menu-item,
.el-submenu__title {
  height: 65px;
  line-height: 35px;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.index-message {
  width: 940px;
  height:216px;
  overflow: hidden;
  margin-top: 20px;
}
.index-message__list {
  float: left;
  height: 100%;
  background: #e9e9e9;
  width: 690px;
  display: flex;
  margin-right: 20px;
}
.index-message__list > a {
  height: 100%;
  border-right: 1px solid #eee;
  float: left;
  background: #fff;
  padding: 23px;
  width: 226px;
  position:relative;
  box-sizing: border-box;
  flex-grow:1;
}
.index-message__list > a h5{
  font-size: 18px;
  color: #181818;
  text-align: center;
  margin-bottom:15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.index-message__list > a:hover h5{
  color:#0e82cb;
}
.index-message__list > a p{
  font-size: 14px;
  color: #999;
  text-align: center;
  line-height: 25px;
  margin-bottom:15px;
  height: 55px;
  overflow: hidden;
}
.index-message__list > a img{
  width: 70px;
  height: 70px;
  position: absolute;
  left: 50%;
  margin-left: -35px;
}
.index-message__login {
  float:right;
  padding-top:10px;
  text-align: center;
  width: 230px;
  position: relative;
  background-color: white;
  box-sizing: border-box;
  height: 100%;
}
.ints{
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  color: #181818;
  letter-spacing: 0;
  height: 20px;
  margin: 8px auto 0;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.car_img{
  width:100%;
  height: 100%;
}
.lo_box{
  width: 200px;
  height: 34px;
  margin: 24px auto 0;
  text-align: center;
}
.lo_box a{
  width: 94px;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  outline: none;
  color:#0e82cb;
  border: 1px solid #0e82cb;
  margin: 0 3px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}
.lo_box a:hover{
  background: #0e82cb;
  color: #fff;
}
.lo_box a:nth-of-type(1){
  background: #0e82cb;
  color: #fff;
}
.half_link{
  position: absolute;
  bottom:0;
  height: 45px;
  line-height: 45px;
  font-size: 13px;
  letter-spacing: 0;
  border-top:1px solid #ededed;
  text-align: center;
  display: table;
  width: 100%;
  margin: 0 auto;
  color: #999;
}
.half_link a{
  text-decoration: none;
  color: #0e82cb;
  display: inline-block;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
}

</style>
